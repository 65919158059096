import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide} from 'vue';
import ConditionUtil ,{IConditionDataObj} from "./conditionUtil";
export default defineComponent ({
    name: "condition",
    props:{
        ownerInst:{type: Object, default:()=>{return{}}},//引用该组件的组件(这里是列表引擎)
        listParam:{type: Object, default:()=>{return{queryParam:{}}}},//主要是为了绑定查询条件
        conditions:{type: Array, default:()=>{return[]}},//主要是为了绑定查询条件
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IConditionDataObj=reactive<IConditionDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            conditionArr:props.conditions,
            otherParams:{

            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ConditionUtil(proxy,dataObj,props,context);
        })
        onMounted(async ()=>{

        })

        watch(() => props.conditions,async (newValue,oldValue) => {
            dataObj.conditionArr=newValue;
            let result=[];
            for(let i=0;i<dataObj.conditionArr.length;i=i+2){
                let temp=[dataObj.conditionArr[i]];
                if((i+1)<dataObj.conditionArr.length)temp.push(dataObj.conditionArr[i+1]);
                result.push(temp);
            }
            dataObj.conditionArr=result;
        })
        return{
            ...toRefs(dataObj)
        }
    }
});